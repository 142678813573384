<template>
  <svg
    viewBox="0 0 42 65"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="16.0586"
      width="9.88235"
      height="64.2353"
    />
    <rect
      y="26.5586"
      width="9.88235"
      height="42"
      transform="rotate(-90 0 26.5586)"
    />
  </svg>
</template>
